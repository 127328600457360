import React, { useState } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "gatsby";

const Privacy = () => {
  const [radioValue, setRadioValue] = useState("privacy");
  const handleRadio = (value) => {
    setRadioValue(value);
  };
  return (
    <>
    <Layout>
      <div className="detailspage">
        <section className="privacypage">
          <div className="container-fluid">
            <div className="wrapperService">
              <div className="innerPageNavigation">
                <ul className="breadcrumbs">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Privacy policy</Link>
                  </li>
                </ul>
              </div>
              <div className="container">
                <div className="row justify-content-center align-items-center casestudy-detail">
                  <div className="col-lg-12 col-xl-12">
                    <h1>Privacy & Cookies policy</h1>
                  </div>
                </div>
                <div className="justify-content-center align-items-center privacyradio">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="radioBtn"
                      id="privacy"
                      value="privacy"
                      checked={radioValue === "privacy"}
                      onChange={() => handleRadio("privacy")}
                    />
                    <label className="form-check-label" htmlFor="radioBtn">
                      Privacy policy
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      name="radioBtn"
                      type="radio"
                      id="cookies"
                      value="cookies"
                      checked={radioValue === "cookies"}
                      onChange={() => handleRadio("cookies")}
                    />
                    <label className="form-check-label" htmlFor="radioBtn">
                      Cookies policy
                    </label>
                  </div>
                </div>
                {radioValue === "privacy" && (
                  <div className="row justify-content-start case-contents new-page-wrapper mt-4">
                    <p className="policyTitle">Privacy policy</p>
                    <p>
                      Your privacy is important to us. It is Xminds’ policy to
                      respect your privacy regarding any information we may
                      collect from you across our website, xminds, and other
                      sites we own and operate.
                    </p>

                    <p>
                      We only ask for personal information when we truly need it
                      to provide a service to you. We collect it by fair and
                      lawful means, with your knowledge and consent. We also let
                      you know why we’re collecting it and how it will be used.
                    </p>

                    <p>
                      We only retain collected information for as long as
                      necessary to provide you with your requested service. What
                      data we store, we’ll protect within commercially
                      acceptable means to prevent loss and theft, as well as
                      unauthorized access, disclosure, copying, use or
                      modification.
                    </p>

                    <p>
                      We don’t share any personally identifying information
                      publicly or with third-parties, except when required to by
                      law.
                    </p>
                    <p>
                      Our website may link to external sites that are not
                      operated by us. Please be aware that we have no control
                      over the content and practices of these sites, and cannot
                      accept responsibility or liability for their respective
                      privacy policies.
                    </p>
                    <p>
                      You are free to refuse our request for your personal
                      information, with the understanding that we may be unable
                      to provide you with some of your desired services.
                    </p>
                    <p>
                      Your continued use of our website will be regarded as
                      acceptance of our practices around privacy and personal
                      information. If you have any questions about how we handle
                      user data and personal information, feel free to contact
                      us.
                    </p>
                  </div>
                )}
                {radioValue === "cookies" && (
                  <div className="row justify-content-start case-contents new-page-wrapper mt-4">
                    <p className="policyTitle">Cookies policy</p>
                    <p>
                      Pages on this site use "cookies", which are small data
                      files that are placed on your device (computer, tablet, or
                      mobile phone) when you browse the internet. Cookies are
                      used to remember your preferences and activity, enhance
                      your experience, and provide personalized advertising.
                    </p>

                    <p>
                      Certain cookies and tags on our website are placed by
                      third-party services that we have integrated into our
                      site. These cookies may affect your experience on other
                      websites you visit after leaving ours, as they identify
                      your browser and internet device. Please note that these
                      cookies do not store any personally identifiable
                      information. However, if you choose to disable these
                      cookies, you may receive less targeted advertising.
                    </p>

                    <p className="policyTitle">Types of cookies:</p>
                    <p>
                      There are two main types of cookies: session cookies and
                      persistent cookies.
                    </p>

                    <p>
                      Session cookies are temporary cookies that expire at the
                      end of a browser session, i.e., when you leave the site.
                      They are used to remember your preferences and improve
                      your experience while you are using our site.
                    </p>
                    <p>
                      Persistent cookies remain on your device for a longer
                      period of time. They are used to remember your preferences
                      and settings across multiple visits to our site.
                    </p>
                    <p className="policyTitle">Third-party cookies:</p>
                    <p>
                      We use third-party cookies to help us understand how our
                      website is being used, such as Google Analytics. These
                      cookies collect information about how visitors use our
                      website, including the number of visitors, the pages they
                      visit, and how long they spend on the site. This
                      information is used to improve our website and make it
                      more user-friendly.
                    </p>

                    <p className="policyTitle">Managing cookies:</p>
                    <p>
                      You can choose to disable cookies in your browser
                      settings. However, please note that if you do this, some
                      parts of our website may not function properly.
                    </p>

                    <p className="policyTitle">Changes to this policy:</p>
                    <p>
                      We reserve the right to modify this Cookies Policy at any
                      time. If we make material changes to this policy, we will
                      notify you by posting the updated policy on our website.
                    </p>

                    <p className="policyTitle">Contact Us</p>
                    <p>
                      If you have any questions about this Cookie Policy, please
                      contact us using the contact information provided on our
                      website.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
    </>
  );
};

export const Head = () => <Seo title="Privacy" />;

export default Privacy;
